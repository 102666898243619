<template>
  <div class="w-75 m-auto">
    <nav class="navbar navbar-expand-lg">
      <div class=" navbar-collapse">
        <div class="navbar-nav mr-auto">
          <router-link :to="{name:'portfolio'}" class="nav-link t-20">
            <h3>Portefeuille</h3>
          </router-link>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <Datepicker style="min-width: 220px" :is24="false" selectText="Valider" cancelText="Annuler"  placeholder="Période" format="dd/MM/yyyy" :lowerLimit="new Date()" v-model="date" range :presetRanges="presetRanges" />
          </div>
        </div>

      </div>
    </nav>
    <hr style="margin-top: 10px; border-top: 2px solid lightgray">
  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths } from 'date-fns';
import {fr} from 'date-fns/locale';

export default {
  name: 'AppHeaderMenu',
  components: { Datepicker },
  data() {
    return {
      locale: fr,
      date: [],
      presetRanges: [
        { label: 'Aujoud\'hui', range: [new Date(), new Date()] },
        { label: 'Ce mois', range: [startOfMonth(new Date()), endOfMonth(new Date())] },
        {
          label: 'Le mois dernier',
          range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
        },
        { label: 'Cette année', range: [startOfYear(new Date()), endOfYear(new Date())] },
      ],
      kanban: false,
    }
  },
  watch: {
    date(data) {
      var date = data ? {start: data[0].toLocaleDateString('en-ZA'), max: data[1].toLocaleDateString('en-ZA')} : null;
      this.$emit('filterDate', date);
    },
  },
  mounted() {
    this.date = null
  }
}
</script>
<style scoped>

.icon:hover {
  color: #6e398e !important;
}

h3{
  text-transform: uppercase;
  font-weight: 700;
}

.width-80{
  width: 80%;
  margin: auto;
}

</style>
