let StatusEnum = {};
let fundingData = [];

class Funding {
    status = {
        WAIT: {
            id: 1,
            text: 'En étude',
            ifa: 'stopwatch',
            icon: 'ellipsis-h',
            color: '',
            colorhex: '#6f42c1',
            editable: false
        },
        WAIT_LEASER: {
            id: 2,
            text: 'Attente d\'éléments',
            ifa: 'question-circle',
            icon: 'ellipsis-h',
            color: '',
            colorhex: '#6f42c1',
            editable: false
        },
        WAIT_SELLER: {
            id: 2,
            text: 'Attente d\'éléments',
            ifa: 'question-circle',
            icon: 'ellipsis-h',
            color: '',
            colorhex: '#EE7202',
            editable: false
        },
        WAIT_MODIF: {
            id: 6,
            text: 'En étude',
            color: '',
            icon: 'ellipsis-h',
            colorhex: '#6f42c1',
            editable: false
        },
        WAIT_AP: {
            id: 3,
            text: 'Accord principe',
            ifa: 'handshake',
            icon: 'check',
            color: 'success',
            colorhex: '#6f42c1',
            editable: false
        },
        OK: {
            id: 4,
            text: 'Accord',
            ifa: 'thumbs-up',
            icon: 'check',
            color: 'success',
            colorhex: '#23A638',
            editable: true,

        },
        OK_RG: {
            id: 7,
            text: 'Accord',
            ifa: 'thumbs-up',
            icon: 'check',
            color: 'success',
            colorhex: '#23A638',
            editable: true,
        },
        KO: {
            id: 5,
            text: 'Refus',
            ifa: 'ban',
            icon: 'times',
            color: 'danger',
            colorhex: '#EA4B58',
            editable: false,
        },
        SS: {
            id: 6,
            text: 'Sans suite',
            icon: 'times',
            color: 'ss',
            colorhex: '#808080',
            editable: true
        }
    }
    constructor() {
        this.initStatus()
    }

    initStatus() {
        StatusEnum = {
            WAIT: {
                id: 1,
                text: 'En étude',
                ifa: 'stopwatch',
                color: '#2185D0',
                accord: []
            },
            WAIT_LEASER: {
                id: 2,
                text: 'En attente d\'éléments',
                ifa: 'question-circle',
                color: '#2185D0',
                accord: []
            },
            WAIT_AP: {
                id: 3,
                text: 'Accord de principe',
                ifa: 'handshake',
                color: '#2185D0',
                accord: []
            },
            OK: {
                id: 4,
                text: 'Accord',
                ifa: 'thumbs-up',
                color: '#27B9A3',
                accord: [],

            },
            OK_RG: {
                id: 6,
                text: 'Accord',
                ifa: 'thumbs-up',
                color: '#27B9A3',
                accord: [],

            },
            KO: {
                id: 5,
                text: 'Refus',
                ifa: 'ban',
                color: '#E03D38',
                accord: []
            },
        };
        fundingData= [];
    }

    async sortFunding(simulation) {
        await this.initStatus();
        if (simulation || simulation.length === 0) {
            simulation.map(function (item) {
            if (item.accord === 'OK') {
                StatusEnum.OK.accord.push(item);
                fundingData.push(item)
            } else if (item.accord === 'WAIT_LEASER' || item.accord === 'WAIT_SELLER') {
                StatusEnum.WAIT_LEASER.accord.push(item);
                fundingData.push(item)
            } else if (item.accord === 'WAIT_AP') {
                StatusEnum.WAIT_AP.accord.push(item);
                fundingData.push(item)
            } else if (item.accord === 'WAIT' || item.accord === 'WAIT_MODIF') {
                StatusEnum.WAIT.accord.push(item);
                fundingData.push(item)
            } else if (item.accord === 'KO' || item.accord === 'SS') {
                StatusEnum.KO.accord.push(item);
                fundingData.push(item)
            }

            })
            return { kanban: StatusEnum, fundingData: fundingData }
        }
        return null
    }
}
export default new Funding
