
<template>
  <div class="row justify-content-between">
    <div class="col">
      <template>
        <h3>Aucune simulation sur cette période / filtre</h3>
      </template>
      <VueMultiselect
          class=""
          v-model="this.filter"
          :options="this.option.map((type) => type.value)"
          :custom-label="(opt) => this.option.find((x) => x.value === opt).text"
          label="Statut"
          :multiple="true"
          placeholder="Trier les simulations par statut avancement du dossier"
      >
        <template  #noResult>
          <span class="font-weight-bold">Aucun filtre disponible.</span>
        </template>
      </VueMultiselect>
    </div>
  </div>

  <div v-if="loading"  class="containers">
    <div class="preloader"></div>
  </div>
    <el-table v-else :data="dataTable" :default-sort="{ prop: 'date_cre', order: 'descending' }"  @row-click="redirectToSimulation" lazy>
    <el-table-column v-if="this.$route.name !== 'funding'" prop="societe.name" label="Nom de la société" width="300">
      <template #default="scope">
        <el-popover v-if="scope.row.societe?.idprof2" trigger="hover" placement="top" width="auto">
          <template #default>
            <div>Siret: {{ scope.row.societe?.idprof2 }}</div>
          </template>
          <template #reference>
            <router-link :to="{ name: 'funding', params: { id: scope.row.societe?.id } }">
              <span class="society">{{ scope.row.societe?.name }}</span>
            </router-link>
          </template>
        </el-popover>
      </template>
    </el-table-column>
    <el-table-column prop="reference" label="Référence" width="90" />
      <el-table-column v-if="hasMultipleCreator" class-name="font-weight-bold" label="Utilisateur" min-width="100">
        <template #default="scope">
          <el-popover trigger="hover" placement="top" width="auto">
            <template #default>
              <div>
                Date de création: <span>{{ formatDate(scope.row.date_cre) }}</span>
              </div>
            </template>
            <template #reference>
              <span>{{ `${scope.row.user.firstname} ${scope.row.user.lastname}` }}</span>
            </template>
          </el-popover>
        </template>
      </el-table-column>
    <el-table-column label="Montant HT">
      <template #default="scope">
        <el-popover trigger="hover" placement="top" width="auto">
          <template #default>
            <div>Durée du contrat: {{ scope.row.duree }}</div>
            <div>
              Période: <span class="text-lowercase">{{ scope.row.opt_periodicite }}</span>
            </div>
            <span>Après échéance: {{ vueNumberFormat(scope.row.echeance, { thousand: ' ', precision: 2, suffix: ' €' }) }}</span>
          </template>
          <template #reference>
            <span>{{ vueNumberFormat(scope.row.montant, { thousand: ' ', precision: 2, suffix: ' €' }) }}</span>
          </template>
        </el-popover>
      </template>
    </el-table-column>
    <el-table-column class-name="font-weight-bold" label="Contrat" min-width="100">
      <template #default="scope">
        <span>{{ contractType[scope.row.fk_type_contrat] ?? 'Type non reconnu' }}</span>
      </template>
    </el-table-column>

    <el-table-column v-if="!hasMultipleCreator" prop="date_cre" label="Création" min-width="80">
      <template #default="scope">
        <span>{{ formatDate(scope.row.date_cre) }}</span>
      </template>
    </el-table-column>
    <el-table-column label="" width="50">
    </el-table-column>
    <el-table-column label="Avancement du dossier" width="400" align="center">
      <template #default="scope">
        <InternalStepper :row="scope.row" />
      </template>
    </el-table-column>
  </el-table>
  <el-pagination v-if="!loading"
    background
    layout="prev, pager, next, sizes"
    :page-sizes="[5, 10, 20, 50, 100]"
    v-model:page-size="maxElem"
    :current-page="pagination?.page + 1 ?? 1"
    @current-change="handleChangePage"
    @size-change="handleSizeChange"
    :total="paginationTotal"
    class="paginate mt-3 mb-3"
  >
  </el-pagination>
</template>

<script>


import moment from 'moment';
import Funding from '../class/funding';
import VueMultiselect from 'vue-multiselect';
import InternalStepper from './DataTableStepper';

export default {
  name: 'DataTable',
  components: {InternalStepper, VueMultiselect },
  emits: ['changeLimit', 'changePage', 'filterData'],
  props: {
    contractType: { required: true },
    dataTable: { required: true },
    pagination: {
      required: false,
    },
    loading: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      removeSimulation: false,
      step: 2,
      maxElem: 20,
      funding: Funding,
      filter: [],
      page: 1,
      option: [
        { text: 'En étude', value: "simulation_accord:WAIT", filterValue: "(t.accord='WAIT')"},
        { text: "En attente d'éléments", value: "simulation_accord:WAIT_SELLER", filterValue: "(t.accord:=:'WAIT_SELLER')" },
        { text: 'Modifications en étude', value: "simulation_accord:WAIT_MODIF", filterValue: "(t.accord:=:'WAIT_MODIF')" },
        { text: 'Accord', value: "simulation_accord:OK", filterValue: "(t.accord:=:'OK')" },
        { text: 'Refus', value: "simulation_accord:KO", filterValue: "(t.accord:=:'KO')" },
        { text: 'Contrat en attente signature client', value: "simulation_contrat:1", filterValue: "(t.contract_status:=:1)" },
        { text: 'Contrat en attente signature Koesio', value: "simulation_contrat:2", filterValue: "(t.contract_status:=:2)" },
        { text: 'Contrat signé', value: "simulation_contrat:4", filterValue: "(t.contract_status:=:4)" },
        { text: 'PV en attente signature client', value: "simulation_delivery_report:1", filterValue: "(t.delivery_report_status:=:1)" },
        { text: 'PV signé', value: "simulation_delivery_report:4", filterValue: "(t.delivery_report_status:=:4)" }
      ]
    };
  },
  mounted() {
    (this.$route.query.filter) ? this.filter = this.$route.query.filter.split(' or ') : this.$emit('filterData', {filters: '', limit: this.maxElem})
  },
  computed: {
    hasMultipleCreator() {
      const user_simulations = this.dataTable.filter((value, index, self) =>
          index === self.findIndex((t) => (
              t.user.firstname === value.user.firstname && t.user.lastname === value.user.lastname
          )));

      return user_simulations.length > 1;
    },
    paginationTotal() {
      return this.pagination?.item_count ?? 0;
    },
    encodedFilterQuery() {
      let filtersQuery = '';

      this.filter.map((filter, key) => {
        const filterRaw = this.option.find(opt => opt.value === filter);
        const operator = key === 0 ? '' : ' or ';
        filtersQuery += `${operator}${filterRaw.filterValue}`
      })

      return filtersQuery;
    },
    encodedFilter() {
      let filtersQuery = '';

      this.filter.map((filter, key) => {
        const filterRaw = this.option.find(opt => opt.value === filter);
        const operator = key === 0 ? '' : ' or ';
        filtersQuery += `${operator}${filterRaw.value}`
      })

      return filtersQuery;
    },
  },
  watch: {
    filter() {
      history.pushState({}, null, (this.filter.length) ? `?filter=${this.encodedFilter}` : '/portfolio');
      this.$emit('filterData', {filters: this.encodedFilterQuery, limit: this.maxElem});
    }
  },
  methods: {
    redirectToSimulation(row) {
      this.$router.push({ name: 'custommerFunding', params: { id: row.societe.id, idFunding: row.rowid } });
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    getFundingText(cell) {
      return Funding.status[cell.accord].text;
    },
    getContratText(cell) {
      return cell.front_infos.status.contrat.label;
    },
    getConformiteText(cell) {
      return cell.front_infos.status.conformite.label;
    },
    handleChangePage(val) {
      this.$emit('changePage', { size: this.maxElem, page: val, filters: this.filter });
    },
    handleSizeChange(newSize) {
      this.page = 1;
      this.handleChangePage(1);
      this.$emit('changeLimit', {size: newSize, filters: this.filter});
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
.containers{
  max-width: 400px;
  margin: 10rem auto;
}

.preloader {
  margin: 20% auto 0;
  transform: translateZ(0);
}

.preloader:before,
.preloader:after {
  content: '';
  position: absolute;
  top: 0;
}

.preloader:before,
.preloader:after,
.preloader {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  animation: animation 1.2s infinite ease-in-out;
}

.preloader {
  animation-delay: -0.16s;
}

.preloader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}

.preloader:after {
  left: 3.5em;
}

@keyframes animation {
  0%,
  80%,
  100% {
    box-shadow: 0 2em 0 -1em #464b5f;
  }
  40% {
    box-shadow: 0 2em 0 0 #6e398e;
  }
}
.containers {
  position: absolute;
  left: 0;
  right: 0;
}
.containers:before {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}
.multiselect__select:focus {
  box-shadow: none;
  border: none;
}

.multiselect__input:focus {
  box-shadow: none !important;
  border: none;
}

.purple-button {
  background-color: #6e398e !important;
  color: white !important;
}

.purple-button:hover {
  background-color: white !important;
  color: #464b5f !important;
}

.action-button:hover {
  color: #464b5f !important;
}

.el-table__row {
  cursor: pointer;
}

.society {
  font-weight: 700;
}

.paginate .el-icon {
  color: #6e398e !important;
}

.is-active {
  background-color: #6e398e !important;
}

.el-pagination.is-background .el-pager li:not(.is-disabled):hover {
  color: #6e398e !important;
}
</style>
