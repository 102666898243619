<template>
  <div class="container">
    <div class="head">
      <h5 class="text-uppercase font-weight-bold p-2">Demande de financement en cours</h5>
      <fa icon="stopwatch" class="fa-large fa-xl header-color-card d-none d-xl-block"></fa>
    </div>
    <hr>
    <div class="position-relative">
      <Loading :value="100" bar-class="bg-info" v-if="!show"></Loading>
    </div>
    <div v-if="show">
      <div v-if="!simulations" class="d-flex align-items-center justify-content-center mt-3">
        <div class="alert alert-warning" role="alert">
          Aucun contrat de financement en cours
        </div>
      </div>
      <div v-else-if="simulations.kanban">
        <DataTable :loading="isSearching" :contractType="contractType" :pagination="fundings.pagination" :dataTable="simulations.fundingData" @filterData="filterSimulation" @changePage="(pageInfo) => handleTableChange(pageInfo.size, pageInfo.page, pageInfo.filters)" @changeLimit="(pageInfo) => handleTableChange(pageInfo.size, 1, pageInfo.filters)" />
      </div>
    </div>
  </div>
</template>

<style scoped>
hr{
  margin-top: 0 !important;
  background-color: #6e398e;
  height: 1px;
}
</style>

<script>
import FundingService from "../services/funding.service"
import Funding from "../class/funding";
import Loading from "./Loading";
import DataTable from '../components/DataTable'

export default {
  name: 'FundingProgress',
  components: {
    Loading,
    DataTable
  },
  data() {
    return {
      isSearching: false,
      simulations: null,
      partenaire: '',
      show: false,
      showError: false,
      fundings: null,
      contractType : null,
    }
  },
  computed: {
    activeFunding(){
      return this.simulations.kanban.OK.accord.length !== 0 &&
          this.simulations.kanban.WAIT.accord.length !== 0 &&
          this.simulations.kanban.WAIT_AP.accord.length !== 0 &&
          this.simulations.kanban.WAIT_LEASER.accord.length !== 0;
    }
  },
  methods: {
    filterSimulation(filterData) {
      this.handleTableChange(filterData.limit, 1, filterData.filters)
    },
    async handleTableChange(limit, page = 1, filter = '') {
      this.isSearching = true;
      setTimeout(async () => {
        this.fundings = await this.getFundings(this.$route.params.id, limit, page - 1, filter);
        this.simulations = await Funding.sortFunding(this.fundings.data);
        this.isSearching = false;
      }, 1000);
    },

    async getFundings(id, limit, page = 0, filter = '') {
      try {
        return (await FundingService.getSimulationsByThirparties(id, limit, page, filter)).data;
      } catch (error) {
        this.showError = true;
        return null;
      }
    },

  },
  async mounted() {
    this.contractType = this.$store.getters.getContractTypes;
    this.fundings = await this.getFundings(this.$route.params.id, 20);
    this.simulations = await Funding.sortFunding(this.fundings.data);
    this.show = true;
  }
}
</script>

