<template>
  <div>
    <nav class="navbar navbar-dark bg-purple main-menu">
      <router-link :to="{name:'dashboard'}" class="navbar-brand spacing-mobile text-uppercase d-sm-block">
        <img class="logo-k" src="@/assets/img/k.jpg" />
      </router-link>
      <div class="search-main fill-space ml-md-3">
        <div class="d-flex ">
          <div class="input-group main-input">
            <div class="input-group-prepend">
              <span class="input-group-text text-muted"> <fa icon="search"/></span>
            </div>
            <input @focus="openModal" type="text" v-model="query" class="form-control text-muted searchButton"
                   aria-label="Text input with dropdown button " placeholder="Recherche rapide">
          </div>
          <div class="input-group">
            <select v-model="filter" class="text-muted form-control">
              <option value="tout">Tout</option>
              <option value="simulations">Simulation</option>
              <option value="clients">Client</option>
              <option value="prospects">Prospect</option>
              <option value="contrats">Contrat</option>
            </select>
            <div class="input-group-append">
              <button class="btn btn-light-red d-flex align-items-center justify-content-center " type="button">
              <template v-if="loader">
                  <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
              </template>
              <template v-else>
                  <fa icon="search-plus" />
              </template>
              </button>
            </div>
          </div>
        </div>
        <div class="position-absolute clickOut" @click="closeModal" v-show="drop"></div>
        <div class="position-relative" v-show="drop">
          <div class="position-absolute containerSearch">
            <div class=" d-flex" v-if="this.filter === 'simulations' || (this.filter === 'tout' && this.simulations?.length)">
              <div class="background-grey d-flex align-items-center justify-content-center info-div">
                <p class="text-muted">Simulation</p>
              </div>
              <div class="w-100">
                <div class="card d-flex justify-content-center align-items-center text-muted h-100" v-if="!this.simulations?.length">
                  <fa icon="ban" class="mr-2"></fa>
                  Aucune Simulation trouvé
                </div>
                <div v-for="simulation in simulations" :key="simulation.id">
                  <router-link :to="{name:'custommerFunding', params: {id: simulation.societe.id, idFunding: simulation.rowid}}" @click="closeModal">
                    <div class="card w-100 d-flex">
                      <div class="d-flex justify-content-center align-items-center pr-2">
                        <fa :icon="informations[simulation.accord]?.icon" :style="{'color': informations[simulation.accord]?.color ?? 'grey' }" class="fa-2x fa-fw"/>
                      </div>
                      <div class="card-body">
                        <h5 class="font-weight-bolder">{{ simulation.societe.name }} - {{ simulation.reference }}</h5>
                        <p class="card-text text-muted">{{ informations[simulation.accord]?.text ?? 'Inconnu' }} - Montant: {{ simulation.montant }}, Echéance: {{ simulation.echeance }}</p>
                      </div>
                      <el-tag class="justify-content-center align-items-center my-auto mr-1" type="warning" effect="dark" size="small">Simulation</el-tag>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class=" d-flex" v-if="this.filter === 'clients' || (this.filter === 'tout' && this.clients)">
              <div class="background-grey d-flex align-items-center justify-content-center info-div">
                <p class="text-muted">Clients</p>
              </div>
              <div class="w-100">
                <div class="card d-flex justify-content-center align-items-center text-muted h-100" v-if="!clients">
                  <fa icon="ban" class="mr-2"></fa>
                  Aucun client trouvé
                </div>
                <div v-for="client in clients" :key="client.id">
                  <router-link :to="{name:'funding', params:{id :client.id ?? 0,siret: getSiret(client)}}" @click="closeModal">
                    <div class="card w-100 d-flex">
                      <div class="card-body w-75">
                        <h5 class="font-weight-bolder">#{{ client.code_client }} - {{ client.name }}</h5>
                        <p class="card-text text-muted">{{ client.address }} - {{ client.zip }} {{ client.town }}</p>
                      </div>
                      <el-tag class="justify-content-center align-items-center my-auto mr-1" type="warning" effect="dark" size="small">Client</el-tag>
                      <div class="d-flex justify-content-center align-items-center pr-2">
                        <img class="img-building" src="../assets/img/flat-building.png">
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class=" d-flex" v-if="this.filter === 'prospects' || (this.filter === 'tout' && this.companies)">
              <div class="card background-grey d-flex align-items-center justify-content-center info-div">
                <p class="text-muted">Prospect</p>
              </div>
              <div class="w-100">
                <div class="card d-flex justify-content-center align-items-center text-muted h-100" v-if="!companies.length">
                  <fa icon="ban" class="mr-2"></fa>
                  Aucun prospect trouvé
                </div>
                <div v-else v-for="company in companies" :key="company.siret">
                  <router-link :to="{name:'newCompanyFunding',params:{ siret: company.siret, customerInfos: company }}" @click="closeModal">
                    <div class="card w-100 d-flex">
                      <div class="card-body">
                        <h5 class="font-weight-bolder">{{ company.denomination }} - {{ company.siret }}</h5>
                        <p class="card-text text-muted">{{ company.numeroVoieEtablissement }} {{ company.typeVoieEtablissement }} {{ company.libelleVoieEtablissement }} - {{ company.codePostalEtablissement }} {{ company.libelleCommuneEtablissement }}</p>
                      </div>
                      <div class="d-flex justify-content-center align-items-center pr-2">
                        <img class="img-building" src="../assets/img/flat-building.png">
                      </div>
                    </div>
                  </router-link>
                </div>

              </div>
            </div>
            <div class="d-flex" v-if="this.filter === 'contrats' || (this.filter === 'tout' && this.contrat)">
              <div class="col-2 background-grey d-flex align-items-center justify-content-center border-top info-div">
                <p class="text-muted">Contrat</p>
              </div>
              <div class="w-100">
                <div class="card d-flex justify-content-center align-items-center text-muted h-100" v-if="!contract">
                  <fa icon="ban" class="mr-2"></fa>
                  Aucun contract trouvé
                </div>
                <router-link :to="{name:'contract',params:{id :contract.client.id,siret:contract.client.siret}}"
                             @click="closeModal" v-if="contract">
                  <div class="card w-100 d-flex">
                    <div class="card-body">
                      <h5 class="font-weight-bolder">#{{ contract.reference }} - {{ contract.type_contrat }}</h5>
                      <p class="card-text text-muted">{{ contract.client.name }} - {{ contract.client.address }},
                        {{ contract.client.zip }} {{ contract.client.town }}</p>
                    </div>
                    <div class="d-flex justify-content-center align-items-center pr-2">
                      <img class="img-building" src="../assets/img/flat-building.png">
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex spacing-mobile ml-auto">
        <div class="mr-4 d-flex align-items-center justify-content-center red">
          <router-link :to="{name:'dashboard'}" class="d-flex align-items-center justify-content-center" >
            <el-tooltip
                class="box-item"
                effect="light"
                content="Voir mon tableau de bord"
                placement="bottom-start"
            >
            <fa icon="home" class="large"/>
              </el-tooltip>
          </router-link>
        </div>
        <div class="mr-4 d-flex align-items-center justify-content-center red">
          <router-link :to="{ name: 'portfolio' }" class="d-flex align-items-center justify-content-center">
            <el-tooltip
                class="box-item"
                effect="light"
                content="Voir mon portefeuille"
                placement="bottom-start"
            >
            <fa icon="list" class="large"/>
            </el-tooltip>
          </router-link>
        </div>
        <div class="mr-4 d-flex align-items-center justify-content-center">
          <a class="d-flex align-items-center justify-content-center" href="#">
            <el-tooltip
                class="box-item"
                effect="light"
                :content="user"
                placement="bottom-start"
            >
              <fa icon="user" class="large"/>
            </el-tooltip>
          </a>
        </div>
        <div class="d-flex align-items-center justify-content-center red">
          <a class="d-flex align-items-center justify-content-center" href="#" @click.prevent="logOut">
            <el-tooltip
                class="box-item"
                effect="light"
                content="Se déconnecter"
                placement="bottom-start"
            >
            <fa icon="sign-out-alt" class="large"/>
            </el-tooltip>
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import {status} from "../helpers/tools";
import _ from 'lodash';

export default {
  name: 'AppHeader',
  data() {
    return {
      query: null,
      client: null,
      contract: null,
      drop: false,
      loader: false,
      simulations: null,
      clients: null,
      companySchema: {
        'siret': null,
        'denomination': null,
        'numeroVoieEtablissement': null,
        'typeVoieEtablissement': null,
        'libelleVoieEtablissement': null,
        'codePostalEtablissement': null,
        'libelleCommuneEtablissement': null
      },
      companiesData: [],
      filter: 'tout',
      informations: status,
    }
  },
  watch: {
    // whenever query input changes, this function will run
    query: _.debounce(function(){
      this.search();
    }, 500),

  },
  computed:{
    companies() {
      let companies = [];
      // eslint-disable-next-line no-unused-vars
      this.companiesData.forEach(data => {
        let company = {};
        Object.keys(this.companySchema).forEach(key => {
          company[key] = eval(this.companySchema[key]);
        });
        companies.push(company);
      });
      return companies;
    },
    user: function (){
      let user = this.$store.getters.getUser ?? {name: 'Alain', firstname: 'Connue'};
      return user.name + ', ' + user.firstname;
    }
  },
  methods: {
    checkResult() {
      if (!this.clients && !this.contract && !this.companiesData) {
        this.$notify({
          duration: 5000,
          text: `Aucun Résultat pour ${this.query}`,
          type: 'warn'
        });
      }
    },

    async search(){
      if (this.query?.length >= 3) {
        this.loader = true;
        await this.getResultSearchBar();
        this.openModal();
      } else {
        this.deleteValue();
        this.closeModal();
      }
    },
    getSiret(client) {
      return client.idprof2 ?? client.idprof1 ?? null
    },
    logOut() {
      this.$store.commit('setUser', null);
      this.$store.commit('setToken', null);
      this.$store.commit('setContractTypes', null);
      this.$router.push('/login');
    },
    async getCompanyFilteredOrganisation(data) {
      try {
        let company = data.trim();
        await this.$http
            .post('/organisation/companies/'+'FR'+'/sites/search?page=1&limit=10', (
                Number.parseInt(company) == company ? {
                'registrationId': company
                } : {
                  'name': company,
                }
              ), {
                headers:{
                  'x-api-key': this.env.VUE_APP_ORGANISATIONSERVICEKEY
                },
                timeout : this.env.VUE_APP_TIMEOUT_SECONDE_SEARCH_BAR * 1000
            }).then((response) => {
              this.companySchema = {
                'siret': 'data.registrationId',
                'denomination': 'data.company.name',
                'numeroVoieEtablissement': 'data.addresses[0]?.houseNumber ?? ""',
                'typeVoieEtablissement': '""',
                'libelleVoieEtablissement': 'data.addresses[0]?.street ?? ""',
                'codePostalEtablissement': 'data.addresses[0]?.zipCode ?? ""',
                'libelleCommuneEtablissement': 'data.addresses[0]?.locality ?? ""'
              }
              this.companiesData = response.data.items.filter((entreprise) => {
                return entreprise.status === 'AC'
              });
              throw DOMException;
            })
      }
      catch (e)
      {
          await this.getCompanyFilteredInsee(data);
      }
    },
    async getCompanyFilteredInsee(data) {
      try {
        let strQuery = '(('
        const words = data.trim().split(' ');

        words.forEach((word, index) => {
          strQuery += `denominationUniteLegale:*${word}*`;
          if (words.length > index + 1) {
            strQuery += ' AND '
          }
        })
        strQuery += `) OR siret:*${data}*`
        strQuery += ") AND periode(etatAdministratifEtablissement:A)"
        await this.$http
          .get('/inseeapi', {
            headers:{
              'Authorization': "Bearer " + this.env.VUE_APP_INSEEKEY
            },
            params: {
              q: strQuery,
            },
          }).then((response) =>{
              this.companySchema = {
                'siret': 'data.siret',
                'denomination': 'data.uniteLegale.denominationUniteLegale',
                'numeroVoieEtablissement': 'data.adresseEtablissement.numeroVoieEtablissement',
                'typeVoieEtablissement': 'data.adresseEtablissement.typeVoieEtablissement',
                'libelleVoieEtablissement': 'data.adresseEtablissement.libelleVoieEtablissement',
                'codePostalEtablissement': 'data.adresseEtablissement.codePostalEtablissement',
                'libelleCommuneEtablissement': 'data.adresseEtablissement.libelleCommuneEtablissement'
              }
              this.companiesData = response.data.etablissements;
          });
      }
      catch (e)
          // eslint-disable-next-line no-empty
      {
      }
    },
    async getCustomersFilters(data) {
      try {
        const res = await this.$http
            .get("/api/thirdparties/", {
              params: {
                sortfield: 't.rowid',
                sortorder: 'ASC',
                limit: 100,
                sqlfilters: `(t.nom:like:'%${data}%') or (t.siret:like:'%${data}%')`
              }
            })

        return res.data;
      }
      catch (e)
      {
        if (e.response.status === 404) {
          return null;
        } else {
          this.$notify({
            duration: 3000,
            text: `Error lors de la recherche des compagnies`,
            type: 'error'
          });
        }
      }
    },
    async getSimulationFilter(data) {
      try {
        const res = await this.$http
            .get("/api/financement/simulation/", {
              params: {
                sortfield: 't.rowid',
                sortorder: 'ASC',
                limit: 100,
                sqlfilters: `(t.reference:like:'%${data}%')`
              }
            })
        return res.data;
      }
      catch (e)
      {
        if (e.response.status === 404) {
          return null;
        } else {
          this.$notify({
            duration: 3000,
            text: `Error lors de la recherche des simulations`,
            type: 'error'
          });
        }
      }
    },
    async getContract(query) {
      try
      {
        const res = await this.$http
            .get("/api/financement/contract", {
              params: {
                reference: query,
                entity: this.$store.getters.getUser.entity,
                ongoing: 1
              }
            })

        return res.data;
      }
      catch (e)
      {
        if (e.response.status === 404) {
          return null;
        } else {
          this.$notify({
            duration: 3000,
            text: `Error lors de la recherche des contracts`,
            type: 'error'
          });
        }
      }

      return null;
    },
    async getResultSearchBar() {
      this.clients = await this.getCustomersFilters(this.query);
      this.simulations = await this.getSimulationFilter(this.query);
      await this.getCompanyFilteredOrganisation(this.query);
      this.contract = await this.getContract(this.query);
      this.checkResult();
      this.loader = false
    },
    openModal() {
      if (this.query?.length >= 3) {
        this.drop = true;
      }
    },
    closeModal() {
      this.drop = false;
    },

    deleteValue() {
      this.contract = null;
      this.clients = null;
    }
  }
}
</script>

<style scoped>

@media screen and (max-width: 767px) {
  .spacing-mobile {
    padding-top: 0.5rem;
  }
  .search-main .main-input {
    max-width: 300px;
  }
}

@media screen and (max-width: 639px) {
  .spacing-mobile {
    order: 2;
  }
}
.search-main {
  margin-right: auto;
  width: -webkit-fill-available;
}

@media screen and (min-width: 640px) {
  .search-main {
    margin-right: auto;
    width: -webkit-fill-available;
    max-width: 530px;
  }

}

.input-group {
  width: auto
}

.input-group-prepend .input-group-text {
  background: #fff;
}

.form-control {
  border-radius: 0;
  font-size: 1em;
  color: #a9a9a9 !important;
}

.btn-light-red .spinner-border {
  width: 1rem;
  height: 1rem;
}

.img-building{
  margin-left: 10px;
  width: 30px;
}


.large{
  font-size: large;
}

.text-muted, .text-muted::placeholder {
  color: #a9a9a9 !important;
}

.main-menu a{

}


.btn {
  color: #fff;
}

.containerSearch {
  background: #ffffff;
  z-index: 9999;
  left: -5px;
  right: -5px;
  border-radius: 3px;
  top: 5px;
  max-height: 350px;
  overflow-y: auto;
  border: 1px solid #CCCCCC;
}


.background-grey {
  background: rgba(0, 0, 0, .125);
}

.card {
  border-radius: 0 !important;
  flex-direction: row !important;
  border-right: none;
  border-left: none;
  border-top: none;
}

.card-body {
  padding: 0.75em;
}

.logo-k{
  height: 30px;
  margin-right: 10px;
}

.search-main .main-input {
  width: 350px;
}

.clickOut {
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  background: transparent;
  height: 100vh;
  z-index: 999;
}
.btn-light-red{
  background: lightcoral;
}

.card{
  max-height: none;
  min-height: 50px;
}

.info-div{
  width: 150px;
  border-bottom: 1px solid rgba(0,0,0,.125)
}

.navbar-dark .navbar-brand{
  font-weight: 800;
}

.searchButton{
  max-width: 600px !important;
}

a{
  color: white;
}

a:hover{
  color: #ACACAC;
}
</style>